import {
    ArcRotateCamera, Camera, Color3, Color4, CubeTexture, DefaultRenderingPipeline, Engine, Nullable, PBRMaterial, Scene, SceneLoader, SceneLoaderFlags, Tools, Vector3
}                         from "@babylonjs/core";
import { GLTFFileLoader } from "@babylonjs/loaders";

let IS_MOBILE = false;
if( /Mobi/.test( navigator.userAgent ) ) {
    IS_MOBILE = true;
}

export class Viewer {
    
    public static readonly CLEAR_COLOR               = Color4.FromHexString( "#E3DD95FF" );
    public static readonly CAMERA_3D_FOV             = Tools.ToRadians( 60.0 );
    public static readonly CAMERA_3D_NEAR            = 10.0;
    public static readonly CAMERA_3D_FAR             = 400.0;
    public static readonly CAMERA_3D_ALPHA           = Tools.ToRadians( 90.0 );
    public static readonly CAMERA_3D_BETA            = Tools.ToRadians( 90.0 );
    public static readonly CAMERA_3D_MIN_BETA        = Tools.ToRadians( 40.0 );
    public static readonly CAMERA_3D_MAX_BETA        = Tools.ToRadians( 140.0 );
    public static readonly CAMERA_3D_MIN_RADIUS      = 90;
    public static readonly CAMERA_3D_MAX_RADIUS      = 350;
    public static readonly CAMERA_3D_RADIUS          = 200.0;
    public static readonly CAMERA_3D_SENSITIVITY     = 3000.0;
    public static readonly CAMERA_3D_INERTIA         = 0.95;
    public static readonly FRAMES_TO_RENDER_ON_INPUT = 60;
    public static readonly ENVIRONMENT_PATH          = "./environments/corridor.env";
    
    private readonly _canvas: Nullable<HTMLCanvasElement>;
    
    private _engine: Nullable<Engine>;
    private _scene: Nullable<Scene>;
    private _camera: Nullable<ArcRotateCamera>;
    private _pipeline: Nullable<DefaultRenderingPipeline>;
    private _remainingFrameCount: number;
    
    
    constructor( canvas: Nullable<HTMLCanvasElement>, onFinish: () => void ) {
        this._canvas              = canvas;
        this._engine              = null;
        this._scene               = null;
        this._camera              = null;
        this._pipeline            = null;
        this._remainingFrameCount = 0;
        
        if( !canvas ) {
            console.error( "Canvas is null." );
            return;
        }
        
        if( !this.initEngine() ) {
            console.error( "Unable to init engine." );
            return;
        }
        
        this.loadScene( () => {
            if( !this.initCamera() ) {
                console.error( "Unable to init camera." );
                return;
            }
            
            if( !this.initEnvironment() ) {
                console.error( "Unable to init environment." );
                return;
            }
            
            if( !this.initPipeline() ) {
                console.error( "Unable to init rendering pipeline." );
                return;
            }
            
            if( !this.start() ) {
                console.error( "Unable to start rendering." );
                return;
            }
    
            setTimeout( () => {
                onFinish();
            }, 2000 );
        }, () => {
            console.error( "Unable to load scene." );
        } );
    }
    
    public render(): void {
        this._remainingFrameCount = Viewer.FRAMES_TO_RENDER_ON_INPUT;
    }
    
    private start(): boolean {
        if( !this._engine ) {
            return false;
        }
        
        this._engine.runRenderLoop( () => {
            this._remainingFrameCount--;
            
            this._camera?.update();
            
            if( this._remainingFrameCount > 0 || this.cameraHasInertia() ) {
                this.renderLoop();
            }
            else {
                this._remainingFrameCount = 0;
            }
        } );
        
        return true;
    }
    
    private renderLoop(): void {
        if( !this._engine || !this._scene ) {
            return;
        }
        
        this._scene.render();
    }
    
    private initEngine(): boolean {
        if( !this._canvas ) {
            return false;
        }
        
        this._engine = new Engine( this._canvas, false, {
            preserveDrawingBuffer: true,
            stencil              : true,
            adaptToDeviceRatio   : true
        } );
        
        if( this._engine == null ) {
            return false;
        }
        
        const hardwareScaling = 1.0 / window.devicePixelRatio;
        this._engine.setHardwareScalingLevel( hardwareScaling );
        
        this._canvas.addEventListener( "mousedown", () => {
            this.render();
        } );
        
        this._canvas.addEventListener( "mouseup", () => {
            this.render();
        } );
        
        this._canvas.addEventListener( "mousemove", ( e: MouseEvent ) => {
            if( e.buttons > 0 ) {
                this.render();
            }
        } );
        
        this._canvas.addEventListener( "wheel", () => {
            this.render();
        } );
        
        this._canvas.addEventListener( "touchstart", () => {
            this.render();
        } );
        
        this._canvas.addEventListener( "touchmove", () => {
            this.render();
        } );
        
        this._canvas.addEventListener( "touchend", () => {
            this.render();
        } );
        
        this._canvas.addEventListener( "touchcancel", () => {
            this.render();
        } );
        
        window.addEventListener( "resize", () => {
            setTimeout( () => {
                if( !this._engine ) {
                    return;
                }
                
                this._engine.resize();
                this.render();
            }, 0 );
        } );
        
        window.dispatchEvent( new Event( 'resize' ) );
        return true;
    }
    
    private loadScene( onSuccess: () => void, onError: () => void ): void {
        if( !this._engine ) {
            onError();
            return;
        }
        
        SceneLoaderFlags.ShowLoadingScreen = false;
        SceneLoader.RegisterPlugin( new GLTFFileLoader() );
        
        SceneLoader.Load( "./scenes/", "cart.glb", this._engine, ( scene: Scene ) => {
            this._scene = scene
            
            this._scene.ambientColor = Color3.Black();
            this._scene.clearColor   = Viewer.CLEAR_COLOR;
            
            const screwMaterial       = this._scene.materials[2] as PBRMaterial;
            screwMaterial.albedoColor = Color3.FromHexString( "#F1FF5A" );
            
            const shellMaterial             = this._scene.materials[0] as PBRMaterial;
            shellMaterial.indexOfRefraction = 1.55;
    
            const labelMaterial     = this._scene.materials[5] as PBRMaterial;
            labelMaterial.roughness = 0.8;
            
            onSuccess();
        }, null, onError );
    }
    
    private initCamera(): boolean {
        if( !this._scene ) {
            return false;
        }
        
        this._camera                         = new ArcRotateCamera( "Camera", Viewer.CAMERA_3D_ALPHA, Viewer.CAMERA_3D_BETA, Viewer.CAMERA_3D_RADIUS, new Vector3( 0, 17, 0 ), this._scene );
        this._camera.fov                     = Viewer.CAMERA_3D_FOV;
        this._camera.minZ                    = Viewer.CAMERA_3D_NEAR;
        this._camera.maxZ                    = Viewer.CAMERA_3D_FAR;
        this._camera.upperBetaLimit          = Viewer.CAMERA_3D_MAX_BETA;
        this._camera.lowerBetaLimit          = Viewer.CAMERA_3D_MIN_BETA;
        this._camera.upperRadiusLimit        = Viewer.CAMERA_3D_MAX_RADIUS;
        this._camera.lowerRadiusLimit        = Viewer.CAMERA_3D_MIN_RADIUS;
        this._camera.radius                  = Viewer.CAMERA_3D_RADIUS;
        this._camera.angularSensibilityX     = Viewer.CAMERA_3D_SENSITIVITY;
        this._camera.angularSensibilityY     = Viewer.CAMERA_3D_SENSITIVITY;
        this._camera.inertia                 = Viewer.CAMERA_3D_INERTIA;
        this._camera.panningSensibility      = 0;
        this._camera.useAutoRotationBehavior = true;
        
        if( this._camera.autoRotationBehavior ) {
            this._camera.autoRotationBehavior.idleRotationSpeed      = 0.05;
            this._camera.autoRotationBehavior.idleRotationWaitTime   = 3000.0;
            this._camera.autoRotationBehavior.idleRotationSpinupTime = 3000.0;
        }
        
        this._camera.attachControl( true );
        
        this._scene.activeCamera                = this._camera;
        this._scene.preventDefaultOnPointerDown = false;
        this._scene.preventDefaultOnPointerUp   = false;
        
        if( IS_MOBILE ) {
            this._camera.radius = Viewer.CAMERA_3D_MAX_RADIUS;
        }
        
        return true;
    }
    
    private initEnvironment(): boolean {
        if( !this._scene ) {
            return false;
        }
        
        const texture                  = CubeTexture.CreateFromPrefilteredData( Viewer.ENVIRONMENT_PATH, this._scene );
        texture.rotationY              = 0.8;
        this._scene.environmentTexture = texture;
        
        return true;
    }
    
    private initPipeline(): boolean {
        if( !this._scene || !this._camera ) {
            return false;
        }
        
        this._pipeline             = new DefaultRenderingPipeline( "Rendering Pipeline", false, this._scene, [this._camera] );
        this._pipeline.fxaaEnabled = true;
        
        return true;
    }
    
    private cameraHasInertia(): boolean {
        if( !this._camera ) {
            return false;
        }
        
        return this._camera.inertialAlphaOffset > 0 || this._camera.inertialBetaOffset > 0 || this._camera.autoRotationBehavior!.rotationInProgress;
    }
}