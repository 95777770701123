












































































































































import Vue        from 'vue'
import { Viewer } from "@/logic/Viewer";

export default Vue.extend( {
    name: 'Main',

    data: function(): any {
        return {
            canvas: null,
            viewer: null,
            loading: true,
            isVideoDialogOpen: false
        }
    },

    mounted: function(): void {
        this.canvas = this.$refs.renderCanvas as HTMLCanvasElement;
        this.viewer = new Viewer( this.canvas, () => {
            this.loading = false;
        } );

        setTimeout( () => {
            window.scrollTo(0,1)
        }, 1000 );
    },

    methods: {
        onVideoClick: function(): void {
            this.isVideoDialogOpen = true;
        }
    },

    watch: {
        isVideoDialogOpen: {
            deep:      false,
            immediate: true,
            handler:   function( newVal ) {
                if( !newVal && this.$refs.video ) {
                    this.$refs.video.pause();
                }
                else if( newVal && this.$refs.video ) {
                    this.$refs.video.currentTime = 0;
                    this.$refs.video.play();
                }
            }
        }
    }
} )
